'use strict';

window.site = window.site || {};

/**
 * Utilities for Google Maps.
 * @class Map
 * @static
 */
site.Form = function Form() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * jQuery elements.
   */
  var $forms = [].slice.call(document.querySelectorAll('.form-request'));
  var $requestTriggers = [].slice.call(document.querySelectorAll('[data-request]'));

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    if ($forms.length > 0) {
      $forms.forEach(function (form) {
        var pristine = new Pristine(form);

        var $requestType = form.querySelector('[name="requestType"]');

        // Checkboxes
        var $isNewsletter = form.querySelector('[name="isNewsletter"]');
        var $isCalled = form.querySelector('[name="isCalled"]');
        var $isRequest = form.querySelector('[name="isRequest"]');

        var $fileInput = form.querySelector('[name="wpId"]');
        var $title = form.closest('.form').querySelector('.form-title');

        var $dynamicBlock = form.querySelector('.form-dynamic');

        // if ($requestType) {
        //   $requestType.addEventListener('change', () => {
        //     const $inputs = $dynamicBlock.querySelectorAll('input[type="text"]');
        //
        //     if ($requestType.value === 'sample') {
        //       $dynamicBlock.classList.add('is-active');
        //
        //       $inputs.forEach(input => {
        //         input.required = true;
        //         input.setAttribute('data-pristine-required-message', input.dataset.errorRequiredMessage);
        //       });
        //     } else {
        //       $dynamicBlock.classList.remove('is-active');
        //
        //       $inputs.forEach(input => {
        //         input.required = false;
        //         input.removeAttribute('data-pristine-required-message');
        //       });
        //     }
        //   });
        // }

        if ($requestTriggers.length > 0) {
          $requestTriggers.forEach(function (trigger) {
            trigger.addEventListener('click', function (e) {
              e.preventDefault();

              var requestType = trigger.dataset.request;
              // $requestType.value = requestType;
              // $title.textContent = trigger.dataset.titleRequest;
              // const $inputs = $dynamicBlock.querySelectorAll('input[type="text"]');

              var $alertSucess = form.querySelector('.alert-success-wrapper');

              if ($alertSucess) {
                $alertSucess.classList.remove('is-active');
              }

              if (requestType === 'sample') {
                if ($isRequest) {
                  $isRequest.checked = true;
                }
              } else {
                if ($isNewsletter) {
                  $isNewsletter.checked = true;
                }
              }

              pristine.reset();
              pristine.destroy();
              pristine = new Pristine(form);
              form.reset();

              if (requestType === 'whitePaper') {
                $fileInput.value = trigger.dataset.requestFileId;
              } else {
                $fileInput.value = '';
              }
            });
          });
        }
      });
    }

    // Return success
    return true;
  };

  var handleRequest = function handleRequest(token) {
    var form = document.querySelector('.form-request');

    var pristine = new Pristine(form);
    var isValid = pristine.validate();

    var formData = new FormData(form);
    var $requestType = form.querySelector('[name="requestType"]');

    if (form && isValid) {
      window.axios.post('/actions/site-module/default/handle-request', formData).then(function (response) {
        if (response.status === 200) {
          pristine.reset();

          var $alertSucess = form.querySelector('.alert-success-wrapper');

          if ($alertSucess) {
            $alertSucess.classList.add('is-active');
          }

          if ($requestType.value === 'newsletter' && $alertSucess) {
            var $alertSuccessTxt = $alertSucess.querySelector('.alert-success');

            $alertSuccessTxt.textContent = $alertSuccessTxt.dataset.newsletterMsg;
          }

          form.reset();

          var topPos = form.getBoundingClientRect().top + window.scrollY;
          window.scroll({
            top: topPos - 55,
            behavior: "smooth"
          });
        }
      }).catch(function (error) {
        console.log(error);
      });
    }
  };

  // Expose public methods & properties
  return {
    init: init,
    handleRequest: handleRequest
  };
}();